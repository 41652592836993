import { v4 as uuidv4 } from 'uuid';
import { runEngine } from '../../../framework/src/RunEngine';
import { IBlock } from '../../../framework/src/IBlock';
import { Message } from '../../../framework/src/Message';
import MessageEnum, {
    getName
} from '../../../framework/src/Messages/MessageEnum';

export default class NameChangeAdapter {
    send: (message: Message) => void;

    constructor() {
        const blockId = uuidv4();
        this.send = message => runEngine.sendMessage(blockId, message);
        runEngine.attachBuildingBlock(this as IBlock, [
            // from where I'm passing the data to the adapter
            getName(MessageEnum.MobileMenuWebMessage)
        ]);
    }

    convert = (from: Message): Message => {
        // where I'm receiving the data from the adapter I've to subscribe this one
        const raiseMessage = new Message(
            getName(MessageEnum.MobileMenuWebData)
        );

        // the data I'm passing to the adapter
        raiseMessage.addData(
            getName(MessageEnum.MobileMenuWebDataMessage),
            from.getData(getName(MessageEnum.MobileMenuWebDataMessage))
        );

        return raiseMessage;
    };

    receive(from: string, message: Message): void {
        this.send(this.convert(message));
    }
}
