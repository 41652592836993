// Customizable Area Start
import React, { useEffect } from "react";
import { Link } from 'react-router-dom';
import { onForegroundMessage, onTokenRefresh } from "./firebase";
import { ReactNotifications, Store } from 'react-notifications-component';
import localStorage from "../../components/src/secureLocalStorage";
import 'react-notifications-component/dist/theme.css';

interface NotificationPayload extends Notification {
    click_action: string
}

interface Props {
    updateFCMToken: (oldToken: string, newToken: string) => void;
}

const FirebaseCloudMessage: React.FC<Props> = ({ updateFCMToken }) => {
    const updateToken = (token: string) => {
        // retrieve old firebase token from local storage
        const oldFirebaseToken = localStorage.getItem("firebaseToken");
        // update new token to local storage 
        localStorage.setItem("firebaseToken", token);
        // call BE API to update token in BE 
        if (oldFirebaseToken && token) {
            updateFCMToken(oldFirebaseToken, token);
        }
    }

    useEffect(() => {
        // listen for foreground message
        const unsubscribe = onForegroundMessage((payload: FcmMessage) => {
            const { notification, data } = payload;
            const redirectUrl = (notification as NotificationPayload)?.click_action || '/';

            // update notification count 
            sessionStorage.setItem("notification_count", data.unread_count);
            window.dispatchEvent(new Event("storage"));
            sessionStorage.setItem("notification_type", "foreground");

            const titleWithLink = (
                <div>
                    <Link to={redirectUrl} style={{ textDecoration: 'none', color: '#fff' }}>
                        {notification.title}
                    </Link>
                </div>
            );

            const messageWithLink = (
                <div>
                    <Link to={redirectUrl} style={{ textDecoration: 'none', color: '#fff' }}>
                        {notification.body}
                    </Link>
                </div>
            );

            Store.addNotification({
                title: titleWithLink,
                message: messageWithLink,
                type: "info",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 5000,
                    onScreen: true,
                    showIcon: true, // Add this line to show the close button
                    pauseOnHover: true, // Add this line to pause the timer on hover
                    click: true // Add this line to dismiss on click
                },
            });
        });

        // on token refresh
        const unsubscribeToken = onTokenRefresh(updateToken);

        return () => {
            // When user Logout
            if (unsubscribe) unsubscribe();
            if (unsubscribeToken) unsubscribeToken();
        }
    }, []);

    return (
        <>
            <ReactNotifications />
        </>
    );
}

export default FirebaseCloudMessage;
// Customizable Area End