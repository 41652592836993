import secureLocalStorage  from  "react-secure-storage";
import { toString } from "lodash";
interface SecureStorage {
  getItem(key: string): string | null;
  setItem(key: string, value: string): void;
  removeItem(key: string): void;
  clear(): void;
}

const localStorage: SecureStorage = {
  getItem: (key: string): any => {
    const item = secureLocalStorage.getItem(key);
    return item ? `${item}` : null
  },
  setItem: (key: string, value: string): void => {
    secureLocalStorage.setItem(key, value);
  },
  removeItem: (key: string): void => {
    secureLocalStorage.removeItem(key);
  },
  clear: (): void => {
    secureLocalStorage.clear();
  }
};
export const getStorageData = (key: string, parseToJson: boolean = false) => {
  const data = localStorage.getItem(key);
  if (parseToJson && data) {
    return JSON.parse(data)
  } else {
    return data ? `${data}` : null;
  }
}

export const setStorageData = (key: string, value: string) => {
  return localStorage.setItem(key, value)
}

export const removeStorageData = (key: string) => {
  return localStorage.removeItem(key)
}

export default localStorage;