export const specialCrawlers = {
    "creationTime": "2024-06-18T22:00:56.000000",
    "prefixes": [
        {
            "ipv6Prefix": "2001:4860:4801:2008::/64"
        },
        {
            "ipv6Prefix": "2001:4860:4801:200c::/64"
        },
        {
            "ipv6Prefix": "2001:4860:4801:200d::/64"
        },
        {
            "ipv6Prefix": "2001:4860:4801:2010::/64"
        },
        {
            "ipv6Prefix": "2001:4860:4801:2011::/64"
        },
        {
            "ipv6Prefix": "2001:4860:4801:2012::/64"
        },
        {
            "ipv6Prefix": "2001:4860:4801:2013::/64"
        },
        {
            "ipv6Prefix": "2001:4860:4801:2014::/64"
        },
        {
            "ipv6Prefix": "2001:4860:4801:2015::/64"
        },
        {
            "ipv6Prefix": "2001:4860:4801:2016::/64"
        },
        {
            "ipv6Prefix": "2001:4860:4801:2017::/64"
        },
        {
            "ipv6Prefix": "2001:4860:4801:2018::/64"
        },
        {
            "ipv6Prefix": "2001:4860:4801:2019::/64"
        },
        {
            "ipv6Prefix": "2001:4860:4801:201a::/64"
        },
        {
            "ipv6Prefix": "2001:4860:4801:201b::/64"
        },
        {
            "ipv6Prefix": "2001:4860:4801:201c::/64"
        },
        {
            "ipv6Prefix": "2001:4860:4801:201d::/64"
        },
        {
            "ipv6Prefix": "2001:4860:4801:201e::/64"
        },
        {
            "ipv6Prefix": "2001:4860:4801:201f::/64"
        },
        {
            "ipv6Prefix": "2001:4860:4801:2020::/64"
        },
        {
            "ipv6Prefix": "2001:4860:4801:2021::/64"
        },
        {
            "ipv6Prefix": "2001:4860:4801:2022::/64"
        },
        {
            "ipv6Prefix": "2001:4860:4801:2023::/64"
        },
        {
            "ipv6Prefix": "2001:4860:4801:2024::/64"
        },
        {
            "ipv6Prefix": "2001:4860:4801:2025::/64"
        },
        {
            "ipv6Prefix": "2001:4860:4801:2026::/64"
        },
        {
            "ipv6Prefix": "2001:4860:4801:2027::/64"
        },
        {
            "ipv6Prefix": "2001:4860:4801:2028::/64"
        },
        {
            "ipv6Prefix": "2001:4860:4801:2029::/64"
        },
        {
            "ipv6Prefix": "2001:4860:4801:202a::/64"
        },
        {
            "ipv6Prefix": "2001:4860:4801:202b::/64"
        },
        {
            "ipv6Prefix": "2001:4860:4801:202c::/64"
        },
        {
            "ipv6Prefix": "2001:4860:4801:202d::/64"
        },
        {
            "ipv6Prefix": "2001:4860:4801:202e::/64"
        },
        {
            "ipv6Prefix": "2001:4860:4801:202f::/64"
        },
        {
            "ipv6Prefix": "2001:4860:4801:2031::/64"
        },
        {
            "ipv6Prefix": "2001:4860:4801:2032::/64"
        },
        {
            "ipv6Prefix": "2001:4860:4801:2033::/64"
        },
        {
            "ipv6Prefix": "2001:4860:4801:2034::/64"
        },
        {
            "ipv6Prefix": "2001:4860:4801:2035::/64"
        },
        {
            "ipv6Prefix": "2001:4860:4801:2036::/64"
        },
        {
            "ipv6Prefix": "2001:4860:4801:2037::/64"
        },
        {
            "ipv6Prefix": "2001:4860:4801:2038::/64"
        },
        {
            "ipv6Prefix": "2001:4860:4801:2039::/64"
        },
        {
            "ipv6Prefix": "2001:4860:4801:203a::/64"
        },
        {
            "ipv6Prefix": "2001:4860:4801:203b::/64"
        },
        {
            "ipv6Prefix": "2001:4860:4801:203c::/64"
        },
        {
            "ipv6Prefix": "2001:4860:4801:203d::/64"
        },
        {
            "ipv6Prefix": "2001:4860:4801:203e::/64"
        },
        {
            "ipv6Prefix": "2001:4860:4801:2040::/64"
        },
        {
            "ipv6Prefix": "2001:4860:4801:2041::/64"
        },
        {
            "ipv6Prefix": "2001:4860:4801:2042::/64"
        },
        {
            "ipv6Prefix": "2001:4860:4801:2043::/64"
        },
        {
            "ipv6Prefix": "2001:4860:4801:2044::/64"
        },
        {
            "ipv6Prefix": "2001:4860:4801:2045::/64"
        },
        {
            "ipv6Prefix": "2001:4860:4801:2046::/64"
        },
        {
            "ipv6Prefix": "2001:4860:4801:2047::/64"
        },
        {
            "ipv6Prefix": "2001:4860:4801:2048::/64"
        },
        {
            "ipv6Prefix": "2001:4860:4801:2049::/64"
        },
        {
            "ipv6Prefix": "2001:4860:4801:204a::/64"
        },
        {
            "ipv6Prefix": "2001:4860:4801:2050::/64"
        },
        {
            "ipv6Prefix": "2001:4860:4801:2051::/64"
        },
        {
            "ipv6Prefix": "2001:4860:4801:2052::/64"
        },
        {
            "ipv6Prefix": "2001:4860:4801:2053::/64"
        },
        {
            "ipv6Prefix": "2001:4860:4801:2054::/64"
        },
        {
            "ipv6Prefix": "2001:4860:4801:2060::/64"
        },
        {
            "ipv6Prefix": "2001:4860:4801:2061::/64"
        },
        {
            "ipv6Prefix": "2001:4860:4801:2062::/64"
        },
        {
            "ipv6Prefix": "2001:4860:4801:2063::/64"
        },
        {
            "ipv6Prefix": "2001:4860:4801:2064::/64"
        },
        {
            "ipv6Prefix": "2001:4860:4801:2065::/64"
        },
        {
            "ipv6Prefix": "2001:4860:4801:2066::/64"
        },
        {
            "ipv6Prefix": "2001:4860:4801:2067::/64"
        },
        {
            "ipv6Prefix": "2001:4860:4801:2068::/64"
        },
        {
            "ipv6Prefix": "2001:4860:4801:2069::/64"
        },
        {
            "ipv6Prefix": "2001:4860:4801:206a::/64"
        },
        {
            "ipv6Prefix": "2001:4860:4801:206b::/64"
        },
        {
            "ipv6Prefix": "2001:4860:4801:206c::/64"
        },
        {
            "ipv6Prefix": "2001:4860:4801:206d::/64"
        },
        {
            "ipv6Prefix": "2001:4860:4801:206e::/64"
        },
        {
            "ipv6Prefix": "2001:4860:4801:206f::/64"
        },
        {
            "ipv6Prefix": "2001:4860:4801:2070::/64"
        },
        {
            "ipv6Prefix": "2001:4860:4801:2071::/64"
        },
        {
            "ipv6Prefix": "2001:4860:4801:2072::/64"
        },
        {
            "ipv6Prefix": "2001:4860:4801:2073::/64"
        },
        {
            "ipv6Prefix": "2001:4860:4801:2074::/64"
        },
        {
            "ipv6Prefix": "2001:4860:4801:2075::/64"
        },
        {
            "ipv6Prefix": "2001:4860:4801:2076::/64"
        },
        {
            "ipv6Prefix": "2001:4860:4801:2077::/64"
        },
        {
            "ipv6Prefix": "2001:4860:4801:2078::/64"
        },
        {
            "ipv6Prefix": "2001:4860:4801:2079::/64"
        },
        {
            "ipv6Prefix": "2001:4860:4801:2080::/64"
        },
        {
            "ipv6Prefix": "2001:4860:4801:2081::/64"
        },
        {
            "ipv6Prefix": "2001:4860:4801:2082::/64"
        },
        {
            "ipv6Prefix": "2001:4860:4801:2083::/64"
        },
        {
            "ipv6Prefix": "2001:4860:4801:2084::/64"
        },
        {
            "ipv6Prefix": "2001:4860:4801:2085::/64"
        },
        {
            "ipv6Prefix": "2001:4860:4801:2086::/64"
        },
        {
            "ipv6Prefix": "2001:4860:4801:2087::/64"
        },
        {
            "ipv6Prefix": "2001:4860:4801:2088::/64"
        },
        {
            "ipv6Prefix": "2001:4860:4801:2090::/64"
        },
        {
            "ipv6Prefix": "2001:4860:4801:2091::/64"
        },
        {
            "ipv6Prefix": "2001:4860:4801:2092::/64"
        },
        {
            "ipv6Prefix": "2001:4860:4801:2093::/64"
        },
        {
            "ipv4Prefix": "108.177.2.0/27"
        },
        {
            "ipv4Prefix": "192.178.17.0/27"
        },
        {
            "ipv4Prefix": "209.85.238.0/27"
        },
        {
            "ipv4Prefix": "209.85.238.128/27"
        },
        {
            "ipv4Prefix": "209.85.238.160/27"
        },
        {
            "ipv4Prefix": "209.85.238.192/27"
        },
        {
            "ipv4Prefix": "209.85.238.224/27"
        },
        {
            "ipv4Prefix": "209.85.238.32/27"
        },
        {
            "ipv4Prefix": "209.85.238.64/27"
        },
        {
            "ipv4Prefix": "209.85.238.96/27"
        },
        {
            "ipv4Prefix": "66.249.87.0/27"
        },
        {
            "ipv4Prefix": "66.249.87.128/27"
        },
        {
            "ipv4Prefix": "66.249.87.160/27"
        },
        {
            "ipv4Prefix": "66.249.87.192/27"
        },
        {
            "ipv4Prefix": "66.249.87.224/27"
        },
        {
            "ipv4Prefix": "66.249.87.32/27"
        },
        {
            "ipv4Prefix": "66.249.87.64/27"
        },
        {
            "ipv4Prefix": "66.249.87.96/27"
        },
        {
            "ipv4Prefix": "66.249.89.0/27"
        },
        {
            "ipv4Prefix": "66.249.89.128/27"
        },
        {
            "ipv4Prefix": "66.249.89.160/27"
        },
        {
            "ipv4Prefix": "66.249.89.224/27"
        },
        {
            "ipv4Prefix": "66.249.89.32/27"
        },
        {
            "ipv4Prefix": "66.249.89.64/27"
        },
        {
            "ipv4Prefix": "66.249.89.96/27"
        },
        {
            "ipv4Prefix": "66.249.90.0/27"
        },
        {
            "ipv4Prefix": "66.249.90.128/27"
        },
        {
            "ipv4Prefix": "66.249.90.160/27"
        },
        {
            "ipv4Prefix": "66.249.90.192/27"
        },
        {
            "ipv4Prefix": "66.249.90.224/27"
        },
        {
            "ipv4Prefix": "66.249.90.32/27"
        },
        {
            "ipv4Prefix": "66.249.90.96/27"
        },
        {
            "ipv4Prefix": "66.249.91.0/27"
        },
        {
            "ipv4Prefix": "66.249.91.128/27"
        },
        {
            "ipv4Prefix": "66.249.91.160/27"
        },
        {
            "ipv4Prefix": "66.249.91.192/27"
        },
        {
            "ipv4Prefix": "66.249.91.224/27"
        },
        {
            "ipv4Prefix": "66.249.91.32/27"
        },
        {
            "ipv4Prefix": "66.249.91.64/27"
        },
        {
            "ipv4Prefix": "66.249.91.96/27"
        },
        {
            "ipv4Prefix": "66.249.92.0/27"
        },
        {
            "ipv4Prefix": "66.249.92.128/27"
        },
        {
            "ipv4Prefix": "66.249.92.160/27"
        },
        {
            "ipv4Prefix": "66.249.92.192/27"
        },
        {
            "ipv4Prefix": "66.249.92.32/27"
        },
        {
            "ipv4Prefix": "66.249.92.96/27"
        },
        {
            "ipv4Prefix": "72.14.199.0/27"
        },
        {
            "ipv4Prefix": "72.14.199.128/27"
        },
        {
            "ipv4Prefix": "72.14.199.160/27"
        },
        {
            "ipv4Prefix": "72.14.199.192/27"
        },
        {
            "ipv4Prefix": "72.14.199.224/27"
        },
        {
            "ipv4Prefix": "72.14.199.32/27"
        },
        {
            "ipv4Prefix": "72.14.199.64/27"
        },
        {
            "ipv4Prefix": "72.14.199.96/27"
        },
        {
            "ipv4Prefix": "74.125.148.0/27"
        },
        {
            "ipv4Prefix": "74.125.148.128/27"
        },
        {
            "ipv4Prefix": "74.125.148.160/27"
        },
        {
            "ipv4Prefix": "74.125.148.192/27"
        },
        {
            "ipv4Prefix": "74.125.148.224/27"
        },
        {
            "ipv4Prefix": "74.125.148.32/27"
        },
        {
            "ipv4Prefix": "74.125.148.64/27"
        },
        {
            "ipv4Prefix": "74.125.148.96/27"
        },
        {
            "ipv4Prefix": "74.125.149.0/27"
        },
        {
            "ipv4Prefix": "74.125.149.128/27"
        },
        {
            "ipv4Prefix": "74.125.149.160/27"
        },
        {
            "ipv4Prefix": "74.125.149.192/27"
        },
        {
            "ipv4Prefix": "74.125.149.224/27"
        },
        {
            "ipv4Prefix": "74.125.149.32/27"
        },
        {
            "ipv4Prefix": "74.125.149.64/27"
        },
        {
            "ipv4Prefix": "74.125.149.96/27"
        },
        {
            "ipv4Prefix": "74.125.150.0/27"
        },
        {
            "ipv4Prefix": "74.125.150.32/27"
        },
        {
            "ipv4Prefix": "74.125.150.64/27"
        },
        {
            "ipv4Prefix": "74.125.151.0/27"
        },
        {
            "ipv4Prefix": "74.125.151.128/27"
        },
        {
            "ipv4Prefix": "74.125.151.160/27"
        },
        {
            "ipv4Prefix": "74.125.151.192/27"
        },
        {
            "ipv4Prefix": "74.125.151.224/27"
        },
        {
            "ipv4Prefix": "74.125.151.32/27"
        },
        {
            "ipv4Prefix": "74.125.151.64/27"
        },
        {
            "ipv4Prefix": "74.125.151.96/27"
        },
        {
            "ipv4Prefix": "74.125.216.0/27"
        },
        {
            "ipv4Prefix": "74.125.216.128/27"
        },
        {
            "ipv4Prefix": "74.125.216.160/27"
        },
        {
            "ipv4Prefix": "74.125.216.192/27"
        },
        {
            "ipv4Prefix": "74.125.216.224/27"
        },
        {
            "ipv4Prefix": "74.125.216.32/27"
        },
        {
            "ipv4Prefix": "74.125.216.64/27"
        },
        {
            "ipv4Prefix": "74.125.216.96/27"
        },
        {
            "ipv4Prefix": "74.125.217.0/27"
        },
        {
            "ipv4Prefix": "74.125.217.128/27"
        },
        {
            "ipv4Prefix": "74.125.217.32/27"
        },
        {
            "ipv4Prefix": "74.125.217.64/27"
        },
        {
            "ipv4Prefix": "74.125.217.96/27"
        },
        {
            "ipv4Prefix": "74.125.218.0/27"
        },
        {
            "ipv4Prefix": "74.125.218.128/27"
        },
        {
            "ipv4Prefix": "74.125.218.160/27"
        },
        {
            "ipv4Prefix": "74.125.218.192/27"
        },
        {
            "ipv4Prefix": "74.125.218.224/27"
        },
        {
            "ipv4Prefix": "74.125.218.32/27"
        },
        {
            "ipv4Prefix": "74.125.218.64/27"
        },
        {
            "ipv4Prefix": "74.125.218.96/27"
        },
        {
            "ipv4Prefix": "74.125.219.0/27"
        },
        {
            "ipv4Prefix": "74.125.219.32/27"
        }
    ]
}