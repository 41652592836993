import { googlebot } from "./googlebot";
import { specialCrawlers } from "./special-crawlers";
import { usertriggeredfetchers } from "./user-triggered-fetchers";

import { chain, has } from 'lodash';
import ipRangeCheck from 'ip-range-check';

// Extract IPv4 prefixes from the provided data
const getIpv4 = (data) => {
  return chain(data)
    .filter(prefix => has(prefix, 'ipv4Prefix'))
    .map('ipv4Prefix')
    .value();
}

// Gather all relevant Google IPs
const googleIps = () => {
  const googlebots = getIpv4(googlebot.prefixes);
  const crawlers = getIpv4(specialCrawlers.prefixes);
  const fetchers = getIpv4(usertriggeredfetchers.prefixes);

  return [...googlebots, ...crawlers, ...fetchers];
}

// Fetch the user's IP address
const getUserIp = async () => {
  try {
    const response = await fetch('https://api.ipify.org?format=json');
    if (!response.ok) {
      throw new Error('Network response was not ok ' + response.statusText);
    }
    const data = await response.json();
    return data.ip;
  } catch (error) {
    console.error('There was a problem with the fetch operation:', error);
  }
}

// Function to check if the user's IP is in the list of Google IPs
const isUserIpInGoogleIps = async (testIpInfor) => {
  try {
    const userIp = testIpInfor || await getUserIp();
    if (!userIp) {
      console.error('Unable to fetch user IP address');
      return false;
    }

    const googleIpList = googleIps();

    // Check if the user IP is in the Google IP ranges using ip-range-check
    const ipInRange = googleIpList.some(prefix => {
      const isGoogle = ipRangeCheck(userIp, prefix)
      if (isGoogle) {
        console.log('=========google prefix========', isGoogle, prefix, userIp)
      }
      return isGoogle

    }
    );
    return ipInRange;
  } catch (error) {
    console.error('There was a problem with the IP check operation:', error);
    return false;
  }
}

export { isUserIpInGoogleIps }

